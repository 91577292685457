<template>
  <div class="container">
    <swiper :options="swiperOption" ref="mySwiper" class="swipper-content">
      <swiper-slide class="swiper-slide">
        <div class="first-page-wrapper">
          <div class="first-page">
            <div class="animation-logo-wrapper" ref="lottieContainer"></div>
            <img class="slogon-wrapper" src="../assets/image/slogon.png" />
            <p class="name-wrapper">IN FUN TECHNOLOGY PTE. LTD.</p>
            <img
              class="charge-img"
              src="../assets/image/fast_charge.png"
              @click="goCharge()"
            />
          </div>

          <swiper
            :options="swiperOption2"
            ref="mySwiper2"
            class="swipper-content2"
          >
            <swiper-slide
              v-for="(item, index) in swiperList"
              :key="index"
              class="swiper-slide2"
            >
              <img :src="item.image" class="swiper-bottom-wrapper" />
            </swiper-slide>
          </swiper>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="second-page-wrapper">
          <img class="pic-wrapper" src="../assets/image/mobile_pic.webp" />
          <p class="name-wrapper">IN FUN TECHNOLOGY PTE. LTD.</p>
          <img class="beauty-wrapper" src="../assets/image/beauty_bg.png" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="third-page-wrapper">
          <img class="pic-wrapper" src="../assets/image/mobile_pic2.webp" />
          <p class="name-wrapper">IN FUN TECHNOLOGY PTE. LTD.</p>
          <img class="beauty-wrapper" src="../assets/image/short_play.png" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="four-page-wrapper">
          <img class="pic-wrapper" src="../assets/image/mobile_pic3.webp" />
          <p class="name-wrapper">IN FUN TECHNOLOGY PTE. LTD.</p>
          <img class="beauty-wrapper" src="../assets/image/short_play.png" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="five-page-wrapper">
          <div class="join-wrapper" @click="showJoin()"></div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="six-page-wrapper">
          <img class="pic-wrapper" src="../assets/image/mobile_pic4.webp" />
          <img class="slogon2-wrapper" src="../assets/image/slogon2.png" />
          <div class="down-wrapper">
            <div class="button-wrapper">
              <img
                class="button-top"
                src="../assets/image/appstore.png"
                @click="redirectToAppStore()"
              />
              <img
                class="button-bottom"
                src="../assets/image/googleplay.png"
                @click="redirectToGooglePlay()"
              />
            </div>
          </div>
          <p class="name-wrapper2">IN FUN TECHNOLOGY PTE. LTD.</p>
        </div>
      </swiper-slide>
    </swiper>
    <div class="tabs" ref="tabs">
      <div
        class="tab-item"
        v-for="(tab, index) in tabs"
        :key="index"
        :class="{ active: index === activeIndex }"
        @click="changeSlide(index)"
      >
        {{ tab }}
        <div class="tab-indicator" v-if="index === activeIndex"></div>
      </div>
    </div>
    <div v-if="isShowJoin" class="join-pop" @click="showJoin()">
      <div class="pop"></div>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import QRCode from "qrcode";
import animationData from "../assets/meoo_animation.json"; // 导入JSON动画文件
import logoUrl from "../assets/meoo.png";

export default {
  data() {
    return {
      tabs: ["首頁", "智能美顏", "優質短劇", "趣味禮物", "合作主播", "下載APP"],
      swiperList: [
        {
          image: require("@/assets/image/carousel01.webp"),
        },
        {
          image: require("@/assets/image/carousel02.webp"),
        },
        {
          image: require("@/assets/image/carousel03.webp"),
        },
        {
          image: require("@/assets/image/carousel04.webp"),
        },
        {
          image: require("@/assets/image/carousel05.webp"),
        },
        {
          image: require("@/assets/image/carousel06.webp"),
        },
      ],
      activeIndex: 0,
      qrCodeImage: "",
      isShowJoin: false,
      swiperOption: {
        slidesPerView: "auto",
        normalizeSlideIndex: false,
        on: {
          slideChange: () => {
            this.activeIndex = this.$refs.mySwiper.swiper.activeIndex;
            this.$nextTick(() => {
              const tabElements =
                this.$refs.tabs.getElementsByClassName("tab-item");
              const tabElement = tabElements[this.activeIndex];
              if (tabElement) {
                const scrollLeft =
                  tabElement.offsetLeft -
                  (this.$refs.tabs.offsetWidth - tabElement.offsetWidth) / 2;
                this.$refs.tabs.scrollLeft = scrollLeft;
              }
            });
          },
        },
      },
      swiperOption2: {
        slidesPerView: "auto",
        spaceBetween: 10,
        normalizeSlideIndex: false,
        autoplay: true,
        loop: true,
      },
    };
  },
  created() {},
  mounted() {
    this.loadAnimation();
    this.generateQRCodeWithLogo();
  },
  methods: {
    changeSlide(index) {
      this.activeIndex = index;
      this.$refs.mySwiper.swiper.slideTo(index);
      this.$nextTick(() => {
        const tabElement =
          this.$refs.tabs.getElementsByClassName("tab-item")[index];
        tabElement.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "start",
        });
      });
    },
    showJoin() {
      this.isShowJoin = !this.isShowJoin;
    },
    goCharge() {
      window.location.href = "https://webpay.meoo.live/";
    },
    loadAnimation() {
      const animation = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData, // JSON动画数据
      });
      animation.setSpeed(0.7);
    },
    redirectToAppStore() {
      window.location.href = "https://apps.apple.com/app/meoo/id6471481453";
    },
    redirectToGooglePlay() {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ufun.livestreaming";
    },
    async generateQRCodeWithLogo() {
      const qrCodeData = "https://www.meoo.live/download/index.html"; // 二维码内容

      const canvas = document.createElement("canvas");
      await QRCode.toCanvas(canvas, qrCodeData, {
        errorCorrectionLevel: "H",
        margin: 1,
        borderRadius: 10,
      });

      const ctx = canvas.getContext("2d");
      const logo = new Image();
      logo.src = logoUrl;
      logo.onload = () => {
        const logoSize = canvas.width * 0.2; // 设置logo大小
        const x = (canvas.width - logoSize) / 2;
        const y = (canvas.height - logoSize) / 2;
        ctx.drawImage(logo, x, y, logoSize, logoSize);

        this.qrCodeImage = canvas.toDataURL();
      };
    },
  },
};
</script>
<style lang="less">
html,
body,
#app,
#app > div {
  width: 100%;
  height: 100% !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
}

.charge-img {
  width: 130px;
  height: 35px;
  margin: 18px 7px 12px 15px;
  animation: zoomInOut 1s infinite;
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1); /* 原始大小 */
  }
  50% {
    transform: scale(1.1); /* 放大到1.5倍 */
  }
}
.tabs {
  position: relative;
  display: flex;
  padding-top: 20px;
  overflow-x: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
  z-index: 10;
}

.tabs ::-webkit-scrollbar {
  display: none;
}

.tabs .tab-item {
  position: relative;
  white-space: nowrap;
  text-align: center;
  padding: 10px;
  margin: 0 5px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.4);
}

.tab-indicator {
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 16px;
  height: 4px;
  background: #ffffff;
  border-radius: 15px;
  transform: translateX(-50%);
}

.tabs .active {
  font-size: 16px;
  color: #ffffff;
  bottom: 6px;
}
.swipper-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;
  width: 100% !important;
}

.first-page-wrapper {
  width: 100%;
  height: 100%;
  background-image: url("../assets/image/mobile_bg.webp");
  background-size: cover;
  overflow: auto;

  .first-page {
    width: 100%;
    height: 500px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .animation-logo-wrapper {
    width: 142px;
    height: 101px;
    margin-top: 40%;
  }
  .slogon-wrapper {
    width: 127px;
    height: 29px;
    margin-top: 24px;
  }

  .name-wrapper {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    color: #ffffff;
    line-height: 12px;
    margin-top: 12px;
  }

  .more-wrapper {
    width: 108px;
    height: 45px;
    margin-top: 97px;
  }

  .swipper-content2 {
    // position: absolute;
    // bottom: 10px;
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
    // margin-top: 150px;

    .swiper-slide2 {
      width: 90% !important;
      height: 100%;
      background: none;
    }

    .swiper-bottom-wrapper {
      width: 95%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.second-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_two1.webp");
  background-size: cover;
}

.third-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_three.webp");
  background-size: cover;
}

.four-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_four.webp");
  background-size: cover;
}

.six-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_six1.webp");
  background-size: cover;

  .slogon2-wrapper {
    width: 234px;
    height: 49px;
    margin-top: 20px;
  }

  .down-wrapper {
    box-sizing: border-box;
    display: flex;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;

    .qr-wrapper {
      flex: 2;
      width: 139px;
      height: 139px;
    }
    .button-wrapper {
      flex: 3;
      display: flex;
      flex-direction: column;
      margin-left: 12px;

      .button-top {
        width: 80%; /* 设置图片宽度 */
        height: auto; /* 自适应高度 */
        margin: 0 auto;
        margin-top: 6px;
      }

      .button-bottom {
        width: 80%; /* 设置图片宽度 */
        height: auto; /* 自适应高度 */
        margin: 0 auto;
        margin-top: 15px;
      }
    }
  }

  .name-wrapper2 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 12px;
    margin-top: 14px;
    padding-left: 15px;
    margin-bottom: 50px;
  }
}

.five-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_five1.webp");
  background-size: cover;

  .join-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 82px;
    height: 27px;
    top: 413px;
  }
}

.pic-wrapper {
  width: calc(100% - 30px);
  height: auto;
  margin-top: 126px;
}

.name-wrapper {
  text-align: left;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  line-height: 12px;
  margin-top: 42px;
  padding-left: 15px;
}

.beauty-wrapper {
  width: calc(100% - 30px);
  height: auto;
  margin-top: 12px;
}

.join-pop {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: flex-end; /* 弹窗底部对齐 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.pop {
  width: 100%;
  height: 305px;
  background-image: url("../assets/image/mobile_join.png");
  background-size: cover;
}
</style>
